import {
  checkIfImageExists,
  isDivisionControlled,
  selectedDivisionId,
  userDetails,
} from "@ui/Utils/helper";
import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";
import { downloadMissingDesigns } from "./List/excelImport";
import HTTPWithoutLoading from "@ui/Utils/httpWithoutLoading";
import commonEnums from "@ui/Utils/commonEnums";

const baseApiUrl = commonConfig.baseApi["data-engine"];

export const syncImageToDesign = async (body = {}) => {
  try {
    const url =
      baseApiUrl + "c/" + enums.models.products + "/syncImageToDesign";

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const chunkImagesUpload = async (
  files,
  params = {},
  { loading = true } = {}
) => {
  try {
    const model = enums.models.products;
    let url = baseApiUrl + model + "/bulkImageUpsert";

    if (loading) {
      const res = await HTTP.upload(url, files, false);

      return res?.data?.data;
    } else {
      const res = await HTTPWithoutLoading.upload(url, files, false);

      return res?.data?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyDataEngineImages = async (products = []) => {
  try {
    const clientId = userDetails?.client?._id;
    const tracedProducts = { found: [], notFound: [] };

    for (let i = 0; i < products.length; i++) {
      const styleNo = products[i].styleNo;

      try {
        const url = `https://storage.googleapis.com/${commonConfig.dataEngineBucketName}/Data-Engine/${clientId}/products/${styleNo}.png`;

        const exist = await checkIfImageExists(url);
        if (exist) {
          tracedProducts.found = [
            ...tracedProducts.found,
            { styleNo, success: true },
          ];
        } else {
          tracedProducts.notFound = [
            ...tracedProducts.notFound,
            { styleNo, success: false },
          ];
        }
      } catch (err) {
        console.log(err);

        tracedProducts.notFound = [
          ...tracedProducts.notFound,
          { styleNo, success: false },
        ];
      }
    }

    downloadMissingDesigns(tracedProducts.notFound);
    return tracedProducts;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const handleBulkUpdate = async (model, body = {}) => {
  try {
    const url = baseApiUrl + "c/" + model + "/handleBulkUpdate";

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPaginatedData = async (
  model,
  { page, pageSize, sort, criteria, filterColumns, ...query } = {},
  additionalHeaders
) => {
  try {
    const url =
      baseApiUrl + model + "/paginate?hook=true&priceAfterDiscount=true";

    const params = {
      ...query,
      filterColumns: filterColumns,
      pageNo: (page || 0) + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSingleRecord = async (model, criteria = {}) => {
  try {
    const url = baseApiUrl + model + "/getSingleRecord";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const search = async (model, criteria = {}, additionalHeaders) => {
  try {
    const url = baseApiUrl + model + "/search";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(baseApiUrl + model + "/" + data?.code, data);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateListRecords = async (model, data) => {
  try {
    const res = await HTTP.post(baseApiUrl + model + "/updateList", { data });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRecord = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(baseApiUrl + model, data, additionalHeaders);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRecordWithHook = async (
  model,
  data,
  additionalHeaders,
  queryParams
) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + model + `?hook=true${queryParams ? `&${queryParams}` : ""}`,
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getByCode = async (model, code, queryParams) => {
  try {
    const url =
      baseApiUrl + model + "/" + code + (queryParams ? `?${queryParams}` : "");

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (
  model,
  data,
  additionalHeaders,
  queryParams
) => {
  try {
    const res = await HTTP.put(
      baseApiUrl +
        model +
        "/" +
        data?.code +
        `?hook=true${queryParams ? `&${queryParams}` : ""}`,
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const signStoredObjects = async (
  model,
  {
    codes = [],
    filterColumns = {
      // fieldWhichNeedsToBeSignedForImage: 1
    },
  }
) => {
  try {
    const url = baseApiUrl + model + "/signStoredObjects";

    const res = await HTTP.post(url, { code: { $in: codes }, filterColumns });
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bulkStockUpsert = async (model, body = {}) => {
  try {
    const url = baseApiUrl + model + "/bulkStockUpsert";

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const initPrinterTemplates = async (
  setTemplates,
  setInitialTemplate = null
) => {
  try {
    const dynamicKey = isDivisionControlled ? selectedDivisionId : "Default";

    const res =
      (await search(enums.models["data-engine"].printerTemplates, {
        filterColumns: { name: 1, tsplCommands: 1 },
      })) || [];

    let localTagTemplate = window.localStorage.getItem(
      commonEnums.localStorage.tagTemplate
    );

    localTagTemplate = localTagTemplate
      ? JSON.parse(localTagTemplate)
      : res[0] || {};

    localTagTemplate = localTagTemplate[dynamicKey];

    if (setTemplates) setTemplates(res);
    if (setInitialTemplate) setInitialTemplate(localTagTemplate);

    return localTagTemplate;
  } catch (err) {
    console.error("Error fetching printer templates:", err);
    return null;
  }
};

export const handleLocalTagUpdate = (value, setSelectedTemplate = null) => {
  const dynamicKey = isDivisionControlled ? selectedDivisionId : "Default";

  let localTagTemplate = window.localStorage.getItem(
    commonEnums.localStorage.tagTemplate
  );

  localTagTemplate = localTagTemplate ? JSON.parse(localTagTemplate) : null;

  if (localTagTemplate?.[dynamicKey]?.name !== value?.name) {
    window.localStorage.setItem(
      commonEnums.localStorage.tagTemplate,
      JSON.stringify({ ...localTagTemplate, [dynamicKey]: value })
    );
  }

  if (setSelectedTemplate) {
    setSelectedTemplate(value);
  }
};
