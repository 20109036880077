import HTTP from "@ui/Utils/http";
import HTTPWithoutLoading from "@ui/Utils/httpWithoutLoading";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApi["e-creatives"];
const dataEngineBaseApiUrl = commonConfig.baseApi["data-engine"];

// caps and small case both is used to autoCamelCase and checking
export const gstRegex =
  /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}$/;

export const getSingleRecord = async (model, criteria = {}) => {
  try {
    // currently used for getEcommerceCarts and getLinkitCarts
    const url = baseApiUrl + model + "/getSingleRecord";

    const res = await HTTPWithoutLoading.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRecord = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(
      dataEngineBaseApiUrl + model,
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(
      dataEngineBaseApiUrl + model + "/" + data?.code,
      data
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getByCode = async (model, code, queryParams) => {
  try {
    const url =
      dataEngineBaseApiUrl +
      model +
      "/" +
      code +
      (queryParams ? `?${queryParams}` : "");

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
