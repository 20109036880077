import React, { useEffect, useRef, useState } from "react";
import enums from "helpers/enums";
import { Tab, Tabs } from "@material-ui/core";
import { fetchLocationName, formatDateToRead } from "@ui/Utils/helper";
import {
  getPaginatedData,
  globalUserControl,
  search,
  softDeleteRecord,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";
import { useNavigate } from "react-router-dom/dist/index";
import MatTable from "@ui/components/MatTable";
import AdvanceSearchFilter from "@ui/Controls/Search/AdvanceSearchFilter";
import Header from "@ui/ComponentUtils/Header";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import commonEnums from "@ui/Utils/commonEnums";
import commonConfig from "config/commonConfig";

const columns = [
  {
    title: "Name",
    field: "firstName",
    render: (row) => (row?.firstName || "") + " " + (row?.lastName || ""),
  },
  {
    title: "Company",
    field: "companyDetails.name",
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Email",
    field: "email",
  },
  // { title: "Role", field: "role" },
  {
    title: "Last Known Location",
    field: "lastKnownLocation",
  },
  {
    title: "Created",
    field: "dateCreated",
    render: (row) => (
      <div>
        <div>
          {(row?.createdBy?.firstName || "") +
            " " +
            (row?.createdBy?.lastName || "")}
        </div>
        <div>{formatDateToRead(row?.dateCreated)}</div>
        <div>{row?.createdBy?.role || ""}</div>
      </div>
    ),
  },
];

const CustomersList = () => {
  const model = enums.models.users,
    searchFields = ["role", "phone", "email"],
    userFields = ["$firstName", "$lastName"],
    headerTitle = "Customers",
    title = "",
    onRowClick = true;

  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 10,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef();
  const [currentTab, setCurrentTab] = useState("Active");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setEmployees(
          (
            await search(commonEnums.models.iam.users, {
              filterColumns: { firstName: 1, lastName: 1 },
            })
          ).map((user) => (user.firstName || "") + (user.lastName || ""))
        );
      } catch (_) {}
    })();
  }, []);

  const overRideRecords = async (records) => {
    records = await Promise.all(
      records.map(async (row) => {
        const lat = row?.lastKnownLocation?.coords?.latitude,
          lng = row?.lastKnownLocation?.coords?.longitude;

        try {
          if (row?.lastKnownLocation?.coords) {
            const location = await fetchLocationName(lat, lng);

            return { ...row, lastKnownLocation: location };
          }
        } catch (err) {
          console.log(err);
        }

        return row;
      })
    );

    return records;
  };

  const getData = async (query = {}) => {
    setListPageTableQuery(query);
    const tabCondition = {
      role: { $ne: enums.roles.admin },
      status: currentTab === "Active" ? true : false,
    };

    if (
      query.criteria?.[query.criteria.length - 1]?.searchElements?.length ||
      filterCriteria?.[filterCriteria.length - 1]?.searchElements?.length
    ) {
      if (query.criteria?.[0]?.$or) {
        query.criteria.unshift(tabCondition);
      } else {
        query.criteria = query.criteria || [];
        query.criteria[0] = tabCondition;
      }
    } else {
      query.criteria = [tabCondition, {}];
    }

    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }
    query = {
      ...query,
    };

    try {
      let response = await getPaginatedData(model, query);

      if (response && response.paginationInfo) {
        let records = response.records || [];

        records = await overRideRecords(records);

        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);

      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const tableActions = [
    {
      icon: () => <AirplanemodeInactiveIcon />,
      tooltip: "Deactivate User",
      disabled: selectedRows?.length !== 1,
      isFreeAction: false,
      hidden: currentTab === "InActive",
      onClick: async (_) => {
        if (
          window.confirm(
            `Are you sure to Deactivate ${
              selectedRows[0]?.firstName + " " + selectedRows[0]?.lastName
            }?`
          )
        ) {
          try {
            await softDeleteRecord(model, selectedRows?.[0]?.code); // this API will auto signout user for all users softDelete in blueprint API

            CustomEventEmitter.emit(
              "alert_success",
              "Deactivated Successfully"
            );
          } catch (err) {
            console.log(err);
          } finally {
            tableRef.current.onQueryChange();
          }
        }
      },
    },
    {
      icon: () => <AirplanemodeActiveIcon />,
      tooltip: "Activate User",
      disabled: selectedRows?.length !== 1,
      isFreeAction: false,
      hidden: currentTab === "Active",
      onClick: async (_) => {
        if (
          window.confirm(
            `Are you sure to Activate  ${
              selectedRows[0]?.firstName + " " + selectedRows[0]?.lastName
            }?`
          )
        ) {
          try {
            await updateRecord(model, {
              code: selectedRows[0].code,
              status: true,
            });

            await globalUserControl(enums.models.users, {
              users: [
                {
                  _id: selectedRows[0]._id,
                  firstName: selectedRows[0].firstName,
                  lastName: selectedRows[0].lastName,
                },
              ],
            });

            CustomEventEmitter.emit("alert_success", "Activated Successfully");
          } catch (err) {
            console.log(err);
          } finally {
            tableRef.current.onQueryChange();
          }
        }
      },
    },
  ];

  const onSelectionChange = (rows) => {
    let currentPageNum = tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }
    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
  };

  const handleSearch = (filterQuery = []) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: filterQuery,
    };
    setFilterCriteria(filterQuery);
    tableRef.current.onQueryChange(queryData);
  };

  const handleChange = (_, tab) => {
    tableRef?.current?.onQueryChange &&
      tableRef.current.onQueryChange({
        page: 0,
        pageSize: listPageTableQuery.pageSize,
      });
    setCurrentTab(tab);
    setSelectedRows();
  };

  const headerActions = [
    {
      label: "Create",
      iconName: "add",
      tooltip: "Create",
      onClick: () => navigate("/" + model + "/create"),
    },
  ];

  return (
    <div>
      <Header links={[]} pageTitle={headerTitle} actions={headerActions} />

      <AdvanceSearchFilter
        searchFields={searchFields}
        userFields={userFields}
        handleSearch={handleSearch}
      />

      <Tabs
        style={{ position: "relative", zIndex: "1", maxWidth: "58%" }}
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="object page tabs"
      >
        <Tab value={"Active"} key={"Active"} label={"Active"} id={"Active"} />
        <Tab
          value={"InActive"}
          key={"InActive"}
          label={"InActive"}
          id={"InActive"}
        />
      </Tabs>

      <MatTable
        tableRef={tableRef}
        actions={tableActions}
        columns={columns}
        data={(query) => getData(query)}
        title={title || ""}
        options={{
          selection: true,
          search: false,
          pageSize: listPageTableQuery.pageSize,
        }}
        onRowClick={(_, selectedRow) => {
          onRowClick && navigate(`/${model}/${selectedRow.code}`);
        }}
        onSelectionChange={onSelectionChange}
        excelImport={{
          importQueryParams: "hook=true",
          model: commonEnums.models["data-engine"].users,
          customBaseApi: commonConfig.baseApi["data-engine"],
          cols: [
            {
              key: "firstName",
              header: "First Name",
              backendRequired: true,
            },
            {
              key: "lastName",
              header: "Last Name",
              backendRequired: true,
            },
            {
              key: "phone",
              header: "Phone",
              unique: true,
              backendRequired: true,
            },
            {
              key: "companyDetails.name",
              header: "Company Name",
            },
            {
              key: "businessType",
              header: "Business",
              enums: Object.values(commonEnums.customerBusinessType),
            },
            {
              key: "companyDetails.kyc.gst",
              header: "GST",
            },
            {
              key: "email",
              header: "Email",
            },

            {
              key: "companyDetails.address.country",
              header: "Country",
            },
            {
              key: "companyDetails.address.state",
              header: "State",
            },
            {
              key: "companyDetails.address.city",
              header: "City",
            },
            {
              key: "companyDetails.address.street",
              header: "Street",
            },
            {
              key: "companyDetails.address.pincode",
              header: "Pin Code",
            },
            {
              key: "manager",
              header: "Manager",
              enums: employees,
            },
          ],
          fileName: "Customers",
        }}
      />
    </div>
  );
};

export default CustomersList;
