import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { createRecord, getByCode, updateRecord } from "../api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";
import { fetchLocationName } from "@ui/Utils/helper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gstRegex } from "../api";

const validationSchema = yup.object().shape({
  phone: yup.string().required("Required"),
  // .matches(/^[0-9]{10}$/, "Invalid Phone"), // Adjust the regex for your phone format
  // email: yup.string().email("Invalid Email"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  companyDetails: yup.object().shape({
    kyc: yup.object().shape({
      gst: yup
        .string()
        .notRequired() // Allows the field to be omitted
        .test(
          "is-valid-gst",
          "Invalid GST number",
          (value) => !value || gstRegex.test(value) // Pass validation if empty or matches regex
        ),
    }),
  }),
});

const entitySchema = {};

const CustomerDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const model = enums.models.users;
  const { watch } = formMethods;
  const code = watch("code");

  const params = useParams();

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          if (res?.lastKnownLocation?.coords) {
            const location = await fetchLocationName(
              res?.lastKnownLocation?.coords?.latitude,
              res?.lastKnownLocation?.coords?.longitude
            );

            res.lastKnownLocation = location;
          }

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      // as yup validation is only there for caps check
      // if (values.companyDetails?.kyc?.gst) {
      //   values.companyDetails.kyc.gst =
      //     values.companyDetails.kyc.gst.toUpperCase();
      // }
      if (!values._id) {
        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecord(model, values);
        navigate(`/${model}/${res?.code}`);
        action = "Updated";
      }

      formMethods.reset(res);
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", action + " Successfully");
    } catch (err) {
      console.log(err);

      CustomEventEmitter.emit(
        "alert_error",
        (err.response?.data?.message?.match("duplicate")
          ? "Phone number already exists"
          : err?.response?.data?.message) || "Something went wrong"
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      {code && <DetailContent isEditable={isEditable} />}
    </FormProvider>
  );
};

export default CustomerDetail;
